import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const HeroWrapper = tw.div`
px-[30px] lg:px-0 text-base_text h-[238px] flex flex-col justify-center items-center lg:justify-end lg:items-start text-center lg:text-start
`;

const Heading = tw.h1`
  text-[36px] md:text-[48px] m-0 !font-[500]
`;

const SubHeading = tw.h2`
  mb-10 whitespace-pre-line !font-[400]
`;

const PromotionsHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiPromotionsPage {
        nodes {
          pageDescription
          pageHeader
        }
      }
    }
  `);
  const { pageHeader, pageDescription } = data.allStrapiPromotionsPage.nodes[0];
  return (
    <HeroWrapper>
      <Heading>{pageHeader}</Heading>
      <SubHeading className="b5">{pageDescription}</SubHeading>
    </HeroWrapper>
  );
};

export default PromotionsHero;

import { graphql } from 'gatsby';
import React from 'react';
import { navigate } from 'gatsby';
import translation from 'intl';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import { PageSEOType } from 'components/types';
import SEOGraphQL from 'components/SEOGraphQL';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import Promotions from 'components/pages/promotions';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';
import NewLayout from 'components/NewLayout';

type Props = {
  data: {
    allStrapiPromotionsPage: {
      nodes: {
        showPromoButtonInHBNavBar: boolean;
        showPromoButtonInHFSNavBar: boolean;
      }[];
    };
  };
};
const pageUrl = `${PREFIX_CANONICAL_URL}/promotions`;

const PromotionsPage = ({ data }: Props) => {
  const { showPromoButtonInHBNavBar, showPromoButtonInHFSNavBar } =
    data.allStrapiPromotionsPage.nodes[0];
  if (!showPromoButtonInHBNavBar && !showPromoButtonInHFSNavBar) {
    if (typeof window !== 'undefined') navigate('/404');
    return <></>;
  }

  return (
    <NewLayout headerStyle="light">
      <BreadcrumbList
        pageLabelId="structuredData.promotions"
        pageUrl={pageUrl}
      />
      <Promotions />
    </NewLayout>
  );
};

export default withIntl(translation)(PromotionsPage);

export const Head = ({
  data,
}: {
  data: {
    allStrapiPromotionsPage: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiPromotionsPage.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiPromotionsPage {
      nodes {
        showPromoButtonInHBNavBar
        showPromoButtonInHFSNavBar
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import translation from './translation';
import Hero from './PromotionsHero';
import PromotionsFilterAndListing from './PromotionsFilterAndListing';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import LayoutContainer from 'components/LayoutContainer';

const Promotions = () => {
  return (
    <LayoutContainer className="pt-[84px]">
      <Hero />
      <PromotionsFilterAndListing />
    </LayoutContainer>
  );
};

export default withIntl(translation)(Promotions);
